import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { css } from "@emotion/react"
import { mediaQuery } from "../utils/mediaQuery"
import { graphql, Link, useStaticQuery } from "gatsby"
import { ImageTicker } from "../components/image-ticker"

const containerStyles = ({ heights }) => css`
  color: white;
  background-color: black;

  ${mediaQuery[1]} {
    margin-top: ${heights.header};
  }
`

const titleStyles = css`
  margin: 0;
  padding: 20px;
  text-align: center;
`

const paragraphStyles = css`
  width: 90%;
  margin: 0 auto;
  padding-bottom: 20px;

  ${mediaQuery[1]} {
    padding-bottom: 10px;
    width: 70%;
  }
`

const linkStyles = css`
  color: inherit;
  text-decoration: underline;
`

const gridStyles = css`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  position: relative;
  grid-gap: 10px;
`

const gridOverlayStyles = css`
  position: absolute;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
`

const overlayLinkStyles = css`
  margin: auto;
  font-size: 1.5em;
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }

  ${mediaQuery[1]} {
    font-size: 3em;
  }
`

const TujoImaging = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: { relativeDirectory: { eq: "imaging" } }) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(
                tracedSVGOptions: { color: "#000000", background: "#ffffff" }
                placeholder: TRACED_SVG
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
    }
  `)

  const images = data.allFile.edges

  return (
    <Layout>
      <Seo title="Imaging" />
      <div css={containerStyles}>
        <div css={gridStyles}>
          <div css={gridOverlayStyles}>
            <a
              href="https://tujoimaging.zenfolio.com/"
              css={[linkStyles, overlayLinkStyles]}
            >
              View Your Photos
            </a>
          </div>

          <ImageTicker images={images} size={12} />
        </div>
        <h2 css={titleStyles}>
          <a css={linkStyles} href="https://tujoimaging.zenfolio.com/">
            Tujo Imaging
          </a>
        </h2>
        <p css={paragraphStyles}>
          Ever seen those epic front page sports shots of the pros in full
          action and wondered why that isnt you? Well, maybe it can be!
        </p>
        <p css={paragraphStyles}>
          Having worked in coordination with dive centers for a number of years,
          taking pictures of their guests to later sell, we thought to ourselves
          why this system wouldn't work in many different recreational sports
          where the participants rarely benefit from a professional photographer
          snapping their best sporting moments!
        </p>
        <p css={paragraphStyles}>
          Hence the creation of Tujo Imaging as part of our small media
          production company.
        </p>
        <p css={paragraphStyles}>
          Our aim is to come along to sports centers, ranging in anything from
          climbing centers to motocross track days, chatting to participants and
          letting them know what we do. After taking the pictures the
          photographers will upload them to our website where guests can flick
          through, select and purchase their favorite shots!
        </p>
        <h3 css={titleStyles}>GUESTS</h3>
        <p css={paragraphStyles}>
          If you had your photos taken by a photographer today or in the last
          few days,{" "}
          <a href="https://tujoimaging.zenfolio.com/" css={linkStyles}>
            click here
          </a>
          , find your photographer and see what he has for you from the date you
          met! Add the ones you like to your basket and once payment is taken
          you'll have access to the high resolution versions for download.
        </p>
        <h3 css={titleStyles}>VENUES</h3>
        <p css={paragraphStyles}>
          If you are a venue and you think this system may well work for you,{" "}
          <Link to="/contact" css={linkStyles}>
            get in touch
          </Link>{" "}
          and we can run you through how it works with venues.
        </p>
      </div>
    </Layout>
  )
}

export default TujoImaging
