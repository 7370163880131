import React, { useState } from "react"

import { useInterval } from "../hooks/useInterval.js"
import { GatsbyImage } from "gatsby-plugin-image"

export const ImageTicker = ({ images, size }) => {
  const [indexes, setIndexes] = useState([...Array(size).keys()])

  useInterval(() => {
    const indexToChange = indexes[Math.floor(Math.random() * indexes.length)]

    let randomIndex = Math.floor(
      Math.random() * (images.length - indexes.length)
    )

    const currentIndexes = [...indexes]

    const sortedExclusions = currentIndexes.sort((a, b) => a - b)

    sortedExclusions.forEach(index =>
      randomIndex < index ? randomIndex : randomIndex++
    )

    const newIndexes = indexes.reduce((acc, current) => {
      return current === indexToChange
        ? acc.concat(randomIndex)
        : acc.concat(current)
    }, [])

    setIndexes(newIndexes)
  }, 1000)

  return indexes.map(index => (
    <GatsbyImage
      image={images[index].node.childImageSharp.gatsbyImageData}
      key={index}
      alt={images[index].node.name}
    />
  ))
}
